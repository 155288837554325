<template>
    <div>
        <van-tabbar class="tabbar-h5" route placeholder active-color="#333333" inactive-color="#92949D">
            <van-tabbar-item replace v-for="(item, index) in tabbarItemList" :key="index" :to="item.to">
                <span>{{ item.text }}</span>
                <template #icon="props">
                    <img class="icon" :src="props.active ? item.active : item.inactive" />
                </template>
            </van-tabbar-item>
        </van-tabbar>
        <div class="tabbar-pc" :style="'background:'+backgroudColor||bg_Color">
            <div class="main">
                <div class="left">
                    <img src="@/assets/index-logo.png" class="logo" alt="" @click="jump('/')">
                    <div :class="selectTab==index&&index>0?'tab-item tab-select':'tab-item'" v-for="(item, index) in tabbarItemList" :key="index" @click="jump(item.to)">
                        <template v-if="index>0">
                            {{ item.text }}
                        </template>
                    </div>
                </div>
                <div class="right">
                    <slot name="right"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import homeActive from "./img/home-active.png";
import homeInactive from "./img/home-inactive.png";
import welfareActive from "./img/welfare-active.png";
import welfareInactive from "./img/welfare-inactive.png";
import safetyActive from "./img/safety-active.png";
import safetyInactive from "./img/safety-inactive.png";
import myActive from "./img/my-active.png";
import myInactive from "./img/my-inactive.png";
export default {
    data() {
        return {
            tabbarItemList: [
                {
                    to: "/index",
                    text: this.$t("tabbar.home"),
                    active: homeActive,
                    inactive: homeInactive,
                },
                {
                    to: "/welfare",
                    text: this.$t("tabbar.welfare"),
                    active: welfareActive,
                    inactive: welfareInactive,
                },
                {
                    to: "/safety",
                    text: this.$t("tabbar.antifraud"),
                    active: safetyActive,
                    inactive: safetyInactive,
                },
                {
                    to: "/my",
                    text: this.$t("tabbar.my"),
                    active: myActive,
                    inactive: myInactive,
                },
            ],
            backgroudColor: ''
        };
    },
    props: {
        bgColor: {
            type: String,
            default() {
                return "transparent";
            },
        },
        selectTab: {
            type: Number,
            default() {
                return 0;
            },
        },
    },
    computed: {
        bg_Color() {
            return this.bgColor;
        }
    },
    mounted() {
        // 监听滚动事件，然后用scrolling这个方法进行相应的处理
        document.addEventListener('scroll', this.scrolling, true);
    },
    methods: {
        jump(url) {
            if (url == '/my') {
                url = '/my/pc'
            }
            this.$router.push(url);
        },
        scrolling() {

            // 滚动条距文档顶部的距离
            let scrollTop =
                document.documentElement.scrollTop || 0;
            // 滚动条滚动的距离
            let scrollStep = scrollTop - this.oldScrollTop;
            // console.log("header scrollObj.scrollTop ", scrollObj.scrollTop);
            // console.log("header 滚动距离 ", scrollTop);
            // 更新——滚动前，滚动条距文档顶部的距离
            this.oldScrollTop = scrollTop;

            //变量windowHeight是可视区的高度
            let windowHeight =
                document.documentElement.clientHeight || document.body.clientHeight;
            //变量scrollHeight是滚动条的总高度
            let scrollHeight =
                document.documentElement.scrollHeight || document.body.scrollHeight;
            //滚动条到底部的条件
            if (scrollTop + windowHeight == scrollHeight) {
                //你想做的事情
                // console.log("header  你已经到底部了");
            }
            if (scrollStep < 0) {
                // console.log("header 滚动条向上滚动了！");
            } else {
                // console.log("header  滚动条向下滚动了！");
                this.backgroudColor = "#fff";
                this.$emit('scrollDown')
            }
            // 判断是否到了最顶部
            if (scrollTop <= 0) {
                // console.log("header 到了最顶部")
                this.backgroudColor = "transparent";
                this.$emit('scrollTop')
            }
        },
    }
};
</script>

<style scoped>
.van-tabbar-item__text {
    white-space: nowrap;
}
.icon {
    width: 28px;
    height: 28px;
}

.tabbar-pc {
    display: none;
}
@media only screen and (min-width: 750px) {
    .tabbar-h5 {
        display: none;
    }
    .tabbar-pc {
        display: block;
        width: 100%;
        height: 90px;
        padding: 0 12.5vw;
        position: fixed;
        left: 0;
        top: 0;
        border-bottom: 1px solid #eae2da;
    }
    .tabbar-pc .main {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .tabbar-pc .main .left {
        display: flex;
        align-items: center;
    }
    .tabbar-pc .main .left .logo {
        width: 122px;
        margin-right: 0.1vw;
    }
    .tabbar-pc .main .left .tab-item {
        display: block;
        height: 38px;
        line-height: 38px;
        font-size: 18px;
        padding: 0 15px;
        color: #666666;
        margin: 0 0.6vw;
        cursor: pointer;
        white-space: nowrap;
        border-radius: 19px 19px 19px 19px;
    }
    .tabbar-pc .main .left .tab-item.tab-select {
        background: #be50ea;
        color: #fff;
    }
    .tabbar-pc .main .right {
        width: 30.7%;
        height: 100%;
        display: flex;
        align-items: center;
    }
}
</style>
